import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function ArticleCard({ post, size }) {
  if (size === "small") {
    return (
      <a href={`/blog/${post.frontmatter.slug}`} className="flex flex-col bg-white rounded overflow-hidden shadow-lg mb-8">
        <div className="w-full rounded-t">
          <GatsbyImage image={getImage(post.frontmatter.image)} className="h-40 w-full shadow object-cover" />
        </div>
        <div className="w-full flex flex-col flex-grow flex-shrink">
          <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
            <h3 className="w-full text-gray-600 text-xs md:text-sm pt-6 px-6">{post.frontmatter.category.toUpperCase()}</h3>
            <h2 className="w-full font-bold text-xl text-gray-900 px-6">{post.frontmatter.title}</h2>
            <p className="text-gray-800 font-serif text-base px-6 mb-5">{post.frontmatter.description}</p>
          </div>
        </div>
      </a>
    )
  } else {
    return (
      <a href={`/blog/${post.frontmatter.slug}`} className="flex flex-col md:flex-row md:h-80 bg-white rounded overflow-hidden shadow-lg mb-8 mx-8">
        <div className="w-full md:w-2/3 rounded-t">
          <GatsbyImage image={getImage(post.frontmatter.image)} className="h-full w-full shadow object-cover" />
        </div>
        <div className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink">
          <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
            <h3 className="w-full text-gray-600 text-xs md:text-sm pt-6 px-6">{post.frontmatter.category.toUpperCase()}</h3>
            <h2 className="w-full font-bold text-xl text-gray-900 px-6">{post.frontmatter.title}</h2>
            <p className="text-gray-800 font-serif text-base px-6 mb-5">{post.frontmatter.description}</p>
          </div>
        </div>
      </a>
    )
  }
}
