import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

export default function Nav() {
  return (
    <div className="w-full border-ediy-300 text-ediy" style={{borderBottomWidth: 1}}>
      <div className="flex items-center md:container md:mx-auto max-w-screen-lg lg:px-0">
        <Link to="/blog" className="p-4">Blog</Link>
        <OutboundLink href="https://youtube.com/eurorackDIY" className="p-4">YouTube</OutboundLink>
        <OutboundLink href="https://instagram.com/eurorackDIY" className="p-4">Instagram</OutboundLink>
        <OutboundLink href="https://github.com/eurorackDIY" className="p-4">GitHub</OutboundLink>
      </div>
    </div>
  )
}
