import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Banner() {
  return (
    <div className="w-full border-ediy-300" style={{borderBottomWidth: 1}}>
      <div className="flex md:justify-start justify-center">
        <div className="flex items-center md:container md:mx-auto max-w-screen-lg p-4 lg:px-0">
          <StaticImage className="w-16 h-16" placeholder="none" src="../images/avatar3.jpg" alt="Site Logo"/>
          <h1 className="text-4xl md:text-5xl font-bold text-ediy pl-4">eurorackDIY</h1>
        </div>
      </div>
    </div>
  )
}

