import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import favicon from '../images/favicon.png'
import Banner from '../components/banner'
import Nav from '../components/nav'
import ArticleCard from '../components/article_card'
import 'katex/dist/katex.min.css'

export default function Blog({ data }) {
  const { posts } = data.blog

  return (
    <>
      <Helmet>
        <title>eurorackDIY Blog</title>
        <link rel="icon" href={favicon} />
      </Helmet>

      <Banner />
      <Nav />

      <main className="max-w-screen-lg container mx-auto pt-8">
        {posts.map(post => (
          <ArticleCard post={post} />
        ))}
      </main>
    </>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          description
          category
          slug
          image {
            childImageSharp {
              gatsbyImageData(
                width: 1440
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
